<template>
    <div class="login" v-loading="Loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="header">
            <div class="logo-login">
                <div class="p d-none d-lg-block"></div>
            </div>
        </div>
        <div class="container login-box" id="login" v-cloak>
            <div class="row">
                <div class="col-lg-6">
                    <div class="login-leftbox d-none d-lg-block">
                        <div class="img"><img src="../../../public/static/regist/images/bg_login1.png" alt=""
                                style="width:377px"></div>
                        <dl>
                            <dt>玩家服务</dt>
                            <dd>独家开放玩家注册入口，<br />提高玩家账号安全</dd>
                        </dl>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="login-form">
                        <h1>玩家注册</h1>
                        <template v-if="info.registered == 1">
                            <div class="item">
                                <input type="text" v-model="form.username" class="form-control" placeholder="请输入账号">
                            </div>
                            

                            <div class="item">
                                <input type="password" v-model="form.password" class="form-control" placeholder="请输入密码">
                            </div>
                            <div class="item">
                                <input type="password" v-model="form.confirm_password" class="form-control"
                                    placeholder="请输入确认密码">
                            </div>


                            <div class="item" v-if="info.additional == 1">
                                <input type="text" v-model="form.qq" class="form-control"
                                    placeholder="请输入联系QQ-找回密码有用">
                            </div>
                            <div class="item" v-if="info.additional == 2">
                                <input type="text" v-model="form.qq" class="form-control"
                                    placeholder="请输入邮箱-找回密码有用">
                            </div>

                            <div class="item">
                                <input type="text" v-model="form.code" maxlength="10" class="form-control"
                                    placeholder="图形码">
                                <span class="get_captcha">
                                    <el-image :src="codeurl" style="width: 125px" @click="getCode" />
                                </span>
                            </div>
                            <div class="item">
                                <a href="javascript:;" class="form-control btn-login flex-row-center"
                                    style="text-align:center;text-decoration:none;" @click="Registered">
                                    <span>注 册</span>
                                </a>
                            </div>
                        </template>

                        <template v-if="info.registered == 2">
                            <div class="item">
                                <input type="text" v-model="form.username" class="form-control" placeholder="请输入手机号">
                            </div>

                            <div class="item">
                                <input type="password" v-model="form.password" class="form-control" placeholder="请输入密码">
                            </div>
                            <div class="item">
                                <input type="password" v-model="form.confirm_password" class="form-control"
                                    placeholder="请输入确认密码">
                            </div>


                            <div class="item" v-if="info.additional == 1">
                                <input type="text" v-model="form.qq" class="form-control"
                                    placeholder="请输入联系QQ-找回密码有用">
                            </div>
                            <div class="item" v-if="info.additional == 2">
                                <input type="text" v-model="form.qq" class="form-control"
                                    placeholder="请输入邮箱-找回密码有用">
                            </div>

                            <div class="item">
                                <input type="text" v-model="form.code" maxlength="10" class="form-control"
                                    placeholder="图形码">
                                <span class="get_captcha">
                                    <el-image :src="codeurl" style="width: 125px" @click="getCode" />
                                </span>
                            </div>

                            <div class="item">
                                <input type="text" v-model="form.sms_code" maxlength="10" class="form-control"
                                    placeholder="请输入短信验证码">
                                <span v-if="smscode > 0" class="get_captcha" style="right:10px;top:55%">{{ smscode
                                }}</span>
                                <span v-else class="get_captcha" style="right:10px;top:55%"
                                    @click="getSmsCode">获取短信码</span>
                            </div>
                            <div class="item">
                                <a href="javascript:;" class="form-control btn-login flex-row-center"
                                    style="text-align:center;text-decoration:none;" @click="Registered">
                                    <span>注 册</span>
                                </a>
                            </div>
                        </template>

                        <template v-if="info.registered == 3">
                            <div class="item">
                                <input type="text" v-model="form.username" class="form-control" placeholder="请输入邮箱号">
                            </div>

                            <div class="item">
                                <input type="password" v-model="form.password" class="form-control" placeholder="请输入密码">
                            </div>
                            <div class="item">
                                <input type="password" v-model="form.confirm_password" class="form-control"
                                    placeholder="请输入确认密码">
                            </div>


                            <div class="item" v-if="info.additional == 1">
                                <input type="text" v-model="form.qq" class="form-control"
                                    placeholder="请输入联系QQ-找回密码有用">
                            </div>
                            <div class="item" v-if="info.additional == 2">
                                <input type="text" v-model="form.qq" class="form-control"
                                    placeholder="请输入邮箱-找回密码有用">
                            </div>
                            

                            <div class="item">
                                <input type="text" v-model="form.code" maxlength="10" class="form-control"
                                    placeholder="图形码">
                                <span class="get_captcha">
                                    <el-image :src="codeurl" style="width: 125px" @click="getCode" />
                                </span>
                            </div>

                            <div class="item">
                                <input type="text" v-model="form.sms_code" maxlength="10" class="form-control"
                                    placeholder="请输入邮箱码">

                                <span v-if="smscode > 0" class="get_captcha" style="right:10px;top:55%">{{ smscode
                                }}</span>
                                <span v-else class="get_captcha" style="right:10px;top:55%"
                                    @click="getMaillCode">获取邮件码</span>
                            </div>

                            <div class="item">
                                <a href="javascript:;" class="form-control btn-login flex-row-center"
                                    style="text-align:center;text-decoration:none;" @click="Registered">
                                    <span>注 册</span>
                                </a>

                            </div>
                        </template>



                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
export default {
    name: "Regist",
    data () {
        return {
            Loading: false,
            codeurl: '',
            click: false,
            smscode: 0,
            timer: null,
            timeryc: null,
            form: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
                codekey: '',
                username: '',
                password: '',
                confirm_password: '',
                code: '',
                sms_code: '',
                qq: '',
            },
            info: {
                registered: 0,
                additional:0,
            }
        };
    },
    created () {
        document.title = "游戏快速注册"
        this.getInfo()
        this.getCode()
    },
    methods: {
        getInfo () {
            this.Loading = true
            this.axios({
                // 默认请求方式为get
                method: 'post',
                url: Vue.prototype.config.weburl + '/regist/getInfo',
                // 传递参数
                data: this.form,
                responseType: 'json'
            }).then(response => {
                this.Loading = false
                if (response.data.code == 200) {
                    this.info = response.data.info
                } else {
                    this.$message({
                        message: response.data.msg,
                        type: 'warning'
                    });
                }
            }).catch(error => {
                this.Loading = false
                // 请求失败，
                console.log(error)
            });
        },
        //获取图形码
        getCode () {
            this.Loading = true
            this.axios({
                // 默认请求方式为get
                method: 'post',
                url: Vue.prototype.config.weburl + '/code',
                // 传递参数
                data: this.form,
                responseType: 'json'
            }).then(response => {
                this.Loading = false
                if (response.data.code == 200) {
                    this.codeurl = response.data.result.img
                    this.form.codekey = response.data.result.key
                } else {
                    this.$message({
                        message: response.data.msg,
                        type: 'warning'
                    });
                }
            }).catch(error => {
                this.Loading = false
                // 请求失败，
                console.log(error)
            });
        },
        //获取短信验证码
        getSmsCode () {
            if (this.click == true) {
                return false
            }
            this.click = true
            this.Loading = true
            this.axios({
                // 默认请求方式为get
                method: 'post',
                url: Vue.prototype.config.weburl + '/regist/sms',
                // 传递参数
                data: this.form,
                responseType: 'json'
            }).then(response => {
                this.Loading = false
                if (response.data.code == 200) {
                    this.$message({
                        message: response.data.msg,
                        type: 'success'
                    });
                    this.smscode = 60;
                    clearInterval(this.timer);
                    this.timer = setInterval(() => {   //设置延迟执行
                        this.smscode--;
                        if (this.smscode <= 0) {
                            this.click = false
                            clearInterval(this.timer);
                        }
                    }, 1000);
                } else {
                    this.click = false
                    this.$message({
                        message: response.data.msg,
                        type: 'warning'
                    });
                }
            }).catch(error => {
                this.click = false
                this.Loading = false
                // 请求失败，
                console.log(error)
            });
        },
        //获取邮件码
        getMaillCode () {
            if (this.click == true) {
                return false
            }
            this.click = true
            this.Loading = true
            this.axios({
                // 默认请求方式为get
                method: 'post',
                url: Vue.prototype.config.weburl + '/regist/mail',
                // 传递参数
                data: this.form,
                responseType: 'json'
            }).then(response => {
                this.Loading = false
                if (response.data.code == 200) {
                    this.$message({
                        message: response.data.msg,
                        type: 'success'
                    });
                    this.smscode = 60;
                    clearInterval(this.timer);
                    this.timer = setInterval(() => {   //设置延迟执行
                        this.smscode--;
                        if (this.smscode <= 0) {
                            this.click = false
                            clearInterval(this.timer);
                        }
                    }, 1000);
                } else {
                    this.click = false
                    this.$message({
                        message: response.data.msg,
                        type: 'warning'
                    });
                }
            }).catch(error => {
                this.click = false
                this.Loading = false
                // 请求失败，
                console.log(error)
            });
        },
        Registered () {
            if (this.Loading == true) {
                return false
            }
            this.Loading = true
            this.axios({
                // 默认请求方式为get
                method: 'post',
                url: Vue.prototype.config.weburl + '/regist/registered',
                // 传递参数
                data: this.form,
                responseType: 'json'
            }).then(response => {
                this.Loading = false
                if (response.data.code == 200) {
                    this.$message({
                        message: response.data.msg,
                        type: 'success'
                    });
                    clearTimeout(this.timeryc);  //清除延迟执行 
                    this.timeryc = setTimeout(() => {   //设置延迟执行
                        location.reload()
                    }, 2000);

                } else {
                    this.$message({
                        message: response.data.msg,
                        type: 'warning'
                    });
                }
            }).catch(error => {
                this.Loading = false
                // 请求失败，
                console.log(error)
            });
        },

    },
};
</script>
<style scoped>
@import "../../../public/static/regist/css/bootstrap.min.css";
@import "../../../public/static/regist/css/login.css";
</style>
<style scoped>
.el-image /deep/ .el-image__inner {
    height: 46px;
}
</style>
<style>
* {
    margin: 0;
    padding: 0;
}

.login {
    width: 100%;
    height: 100%;
    position: fixed;
}
</style>
